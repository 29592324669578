<template>
  <div class="customerDetails">
    <div class="left">
      <div class="upxx">
        <p class="title">顾客资料</p>
        <img v-if="showxgCs" @click="upData" src="../../../assets/img/xg.png" alt="">
      </div>
      <div class="title">{{csUser.CustomerName}}</div>
      <p class="padding-30">姓名</p>
      <div class="title">{{csUser.CustomerTel}}</div>
      <p class="padding-30">联系方式</p>
      <div class="title">{{csUser.CustomerBirthday}}</div>
      <p class="padding-30">出生日期</p>


      <div class="gkly">
        <el-button type="primary" @click="openTjr">推荐人</el-button>
      </div>

      <div class="gkly">
        <p style="margin-right: 10px;">顾客来源：</p>
        <el-select
            class="srr"
            v-model="cs.CustomerRegSource"
            filterable
            allow-create
            collapse-tags
            default-first-option
            placeholder="请选择或输入">
          <el-option
              v-for="item in op"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="gkly">
        <p>客户经理1：</p>
        <el-select
            class="xzlex"
            @change="khjlbh('CustomerServiceExpert1', val1)"
            filterable
            allow-create
            v-model="val1" placeholder="请选择类型">
          <el-option
              v-for="item in userList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="gkly">
        <p>客户经理2：</p>
        <el-select
            class="xzlex"
            @change="khjlbh('CustomerServiceExpert2', val2)"
            filterable
            allow-create
            v-model="val2" placeholder="请选择类型">
          <el-option
              v-for="item in userList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>


    </div>
    <div class="middle">
      <div :class="[current==1 ? 'borderNone' : '']" @click="teb(1)">
        <p>就诊记录</p>
        <p v-if="current==1"> > </p>
      </div>
      <div :class="[current==2 ? 'borderNone' : '']" @click="teb(2)">
        <p>销售记录</p>
        <p v-if="current==2"> > </p>
      </div>
      <div :class="[current==3 ? 'borderNone' : '']" @click="teb(3)">
        <p>预约咨询</p>
        <p v-if="current==3"> > </p>
      </div>
      <div :class="[current==4 ? 'borderNone' : '']" @click="teb(4)">
        <p>筛查记录</p>
        <p v-if="current==4"> > </p>
      </div>
      <div :class="[current==5 ? 'borderNone' : '']" @click="teb(5)">
        <p>积分记录</p>
        <p v-if="current==5"> > </p>
      </div>
<!--      <div :class="[current==6 ? 'borderNone' : '']" @click="teb(6)">-->
<!--        <p>历史就诊</p>-->
<!--        <p v-if="current==6"> > </p>-->
<!--      </div>-->
      <div @click="addCS">
        <img src="../../../assets/img/xjcz.png" alt="">
        <span>{{texts}}</span>
      </div>
    </div>
    <div class="right">
      <ConsultingRecords :sx="sx" v-show="current==1"></ConsultingRecords>
      <SalesRecord :idxs="current" v-show="current==2"></SalesRecord>
      <consultingBooking :idxs="current" v-show="current==3"></consultingBooking>
      <HealthCheckListS v-if="current==4" ref="HealthCheckList"></HealthCheckListS>
      <JFJL v-if="current==5"></JFJL>
    </div>
    <div class="bottom">
      <el-button @click="cancellation" type="warning" class="zx">注销</el-button>
    </div>

    <el-dialog
        title="排班列表"
        :visible.sync="dialogVisible"
        width="80%"
        >
      <newZx></newZx>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="showxg"
        width="60%">
      <div>
        <div class="upcs">
          <p>姓名</p>
          <el-input class="upcsrs" v-model="cs.CustomerName" placeholder="请输入内容"></el-input>
        </div>
        <div class="upcs">
          <p>性别</p>
          <el-radio v-model="cs.CustomerGender" label="1">男</el-radio>
          <el-radio v-model="cs.CustomerGender" label="0">女</el-radio>
        </div>
        <div class="upcs">
          <p>电话</p>
          <el-input class="upcsrs" v-model="cs.CustomerTel" placeholder="请输入内容"></el-input>
        </div>
        <div class="upcs">
          <p>身份证</p>
          <el-input class="upcsrs" v-model="sfz" placeholder="请输入内容"></el-input>
        </div>
        <div class="upcs">
          <p>生日</p>
          <el-date-picker
              v-model="rq"
              @change="rqbh"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              :editable="false"
              placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="upcs">
          <p>地区</p>
          <el-cascader
              :props="{value: 'AreaId', label: 'AreaName', children: 'ListArea'}"
              v-model="ssx"
              :options="options"
              @change="handleChange">

          </el-cascader>
        </div>
        <div class="upcs">
          <p>详细地址</p>
          <el-input class="upcsrs" v-model="cs.CustomerAddress" placeholder="请输入内容"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
      <el-button @click="qd(0)">取 消</el-button>
      <el-button type="primary"  @click="qd(1)">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="推荐人"
        :visible.sync="showtjr"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="60%">
      <div v-if="showytjr">
        <p style="color: #0a0a0a; font-size: 15px;">您的推荐人:</p>
        <div class="upcs">
          <p>姓 名</p>
          <el-input class="upcsrs" v-model="tjrObj.CustomerName" disabled placeholder="请输入内容"></el-input>
        </div>
        <div class="upcs">
          <p>电 话</p>
          <el-input class="upcsrs" v-model="tjrObj.CustomerTel" disabled placeholder="请输入内容"></el-input>
        </div>
        <div class="upcs">
          <p>推荐日期</p>
          <el-input class="upcsrs" v-model="tjrObj.ReferrerTime" disabled placeholder="请输入内容"></el-input>
        </div>
        <el-button type="primary"  @click="showtjr = false">返回</el-button>
      </div>
      <div v-if="showcztjr">
        <p style="color: #0a0a0a; font-size: 15px;">请输入推荐人信息：</p>
        <div class="upcs">
          <p>推荐号</p>
          <el-input class="upcsrs" v-model="tjrObj.CustomerId" placeholder="请输入内容"></el-input>
        </div>
        <div class="upcs">
          <p>推荐人手机</p>
          <el-input class="upcsrs" v-model="tjrObj.CustomerTel" placeholder="请输入内容"></el-input>
        </div>
        <el-button type="warning" @click="gbtjr">关闭</el-button>
        <el-button type="primary"  @click="czTjr(1)">查找</el-button>
      </div>
      <div v-if="showbdtjr">
        <p style="color: #0a0a0a; font-size: 15px;">请确认推荐人:</p>
        <div class="upcs">
          <p>姓 名</p>
          <el-input class="upcsrs" v-model="tjrObj.CustomerName" disabled placeholder="请输入内容"></el-input>
        </div>
        <div class="upcs">
          <p>电 话</p>
          <el-input class="upcsrs" v-model="tjrObj.CustomerTel" disabled placeholder="请输入内容"></el-input>
        </div>
        <el-button type="warning" @click="gbtjr">关闭</el-button>
        <el-button type="primary"  @click="czTjr(2)">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import ConsultingRecords from '@/components/customerDetailss/consultingRecords.vue'
import {Loading} from "element-ui";
import pf from "@/publicFn/baseFn.js"
import isbc from "../../../store/moddules/isbc";
export default {
  name: "customerDetails",
  data() {
    return {
      userList: [],
      current: 1,
      texts: '新建初诊', //新建初诊的文字
      dialogVisible: false,
      sx: false,
      showxg: false,
      cs: {CustomerRegSource: ''},
      sfz: '',
      showsr: false,
      rq: '',
      yl: '',
      op: [
        {
          value: '美团',
          label: '美团'
        },
        {
          value: '官网',
          label: '官网'
        },
        {
          value: '公众号',
          label: '公众号'
        },
        {
          value: '朋友推荐',
          label: '朋友推荐'
        },
        {
          value: '二开',
          label: '二开'
        },
        {
          value: '全员营销',
          label: '全员营销'
        },
        {
          value: '传统线下',
          label: '传统线下'
        },
        {
          value: '商务合作',
          label: '商务合作'
        },
        {
          value: '网络咨询',
          label: '网络咨询'
        },
        {
          value: '自然到院',
          label: '自然到院'
        },
        {
          value: '媒体置换',
          label: '媒体置换'
        },
        {
          value: '',
          label: ''
        }
      ],
      val1: '',
      val2: '',
      ssx: [],
      options: [],
      xxdz: '',
      showxgCs: false,
      showtjr: false,
      showytjr: true,
      showcztjr: false,
      showbdtjr: false,

      tjrObj: {},

    }
  },
  watch: {
    "$store.state.users.logged": function (n, o) {
      if (!n) this.cancellation()
    },
    'cs':{
      deep: true,
      handler(n) {
        // console.log(n)
        // this.$store.commit('users/UpCsUser', n)
        // this.$store.state.users.CsUser = n
        // this._api.userCurdApi.upCSLY(n.CustomerRegSource)
        // .then( res => {
        //   // console.log(res)
        // })
      }
    }
  },
  created() {
    this.cs = JSON.parse(JSON.stringify(this.$store.state.users.CsUser))
    this.sfz = this.cs.CustomerIDCardNo
    // console.log(this.cs.CustomerAreaIndex)

    let arr = this.$store.state.users.user.Company.ListCompanyConfig
    if (arr && arr.length) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].BCKey == 'CompanyVPEditCustomer' && arr[i].BCValue == 1) {
          this.showxgCs = true
          break
        }
      }
    }

    this.rq = new Date(this.cs.CustomerBirthday)
     if (!this.$store.state.users.logged) {
       this.cancellation()
     }

     this.getsf()

    this._api.userCurdApi.getEmployeesList()
        .then(res => {
          if (res.GetListResult && res.GetListResult.length>0) {
            this.userList = res.GetListResult.map(item => {
              return {
                label: `${item.ExpertTel}(${item.ExpertName})`,
                value: `${item.ExpertId}`
              }
            })
            this.userList.unshift({
              label: '',
              value: ''
            })
            return true
          }
        })
        .then(res => {
          this._api.physicianVisits.getKfjl()
          .then(res => {
            if (res.GetListResult && res.GetListResult.length) {
              for (let j = 0; j < res.GetListResult.length; j++) {
                if (res.GetListResult[j].CCRType == "CustomerServiceExpert1") {
                  this.val1 = res.GetListResult[j].CCRValue
                }
                if (res.GetListResult[j].CCRType == "CustomerServiceExpert2") {
                  this.val2 = res.GetListResult[j].CCRValue
                }
              }
            }
          })
    })


    this._api.userCurdApi.getCSly()
        .then(res => {
          if (res.Result && res.Result.Items.length) {
            let arr = []
            for (let i = 0; i < res.Result.Items.length; i++) {
              arr.push({
                value: res.Result.Items[i].Name,
                label: res.Result.Items[i].Name
              })
            }
            this.op = arr
          }
        })
  },
  computed: {
    csUser() {
      return this.$store.state.users.CsUser
    }
  },
  methods: {
    teb(idx) {
      this.current = idx
      if (idx == 1) {
        this.texts = '新建初诊'
      }else if (idx == 2){
        this.texts = '新建销售'
      }else if (idx == 3){
        this.texts = '新建预约'
      }else if (idx == 4){
        this.texts = '新建筛查'
      }else if (idx == 5) {
        this.texts = ''
      }else if (idx == 6) {
        this.texts = '新建历史就诊'
      }
    },
    cancellation() {
      this.current = 1
      this.$store.commit('users/upCsUserItem', '')
      this.$store.commit('users/UpCsUser', '' )
      this.$store.commit('users/upCsAge', '')
      this.$store.commit('physicianVisits/upCancellation', true)
      this.$store.commit('physicianVisits/upXzConsulting',  '')
      this.$store.commit('isbc/upall', '')
      pf.clCsData()
      this.$emit('isLing', true)
    },

    addCS() {

      if (!this.$store.state.users.employees) {
        this.$alert("专家未登录，请先登录", "提示")
            .then(res => {
              this.$router.push({
                path: '/employeesLanding',
                query: {types: 1001}
              })
            })
        return
      }

      if (this.current == 1) {
        this._api.physicianVisits.addNewChoose()
            .then(res => {
              if (res.InsertResult) {
                this.sx = true
                this.$store.commit('physicianVisits/upIsqm', true)
                this.$store.commit('physicianVisits/upissgqm', true)
                this.$store.commit('physicianVisits/upXzConsulting',  res.InsertResult.Choose.ChooseId)
                this.$store.commit('physicianVisits/upJump', 1)
                this.$store.commit('users/upCsObj', JSON.parse(JSON.stringify(res.InsertResult.Choose)))
                pf.initTime()
                this.$router.push({path: '/'})
              }
            })
      }else if (this.current == 3) {
        this.dialogVisible = true
      }else if(this.current == 4) {
        this._api.publicApi.HealthCheckInsert()
        .then(res => {
          // console.log(res)
          if (res.Id) {
            this.$refs.HealthCheckList.getxx()
          }
        })
      } else {
        this.$router.push({path: '/settlementCenter'})
      }
    },

    upData() {
      this.showxg = true
      // let cmid = this.$store.state.users.user.Company.CompanyId
      // cmid = cmid.toLowerCase()
      // if ( cmid == 'ab8fc0bc-3d9b-47f2-954b-71afb6d4c3b8' || cmid =='350d0b9d-22a9-4bce-97dd-1e57a95382c7' || cmid == '22f64a1a-d55f-4a3e-8f9c-2a0aecda4296' || cmid.toUpperCase() =='0510D02D-3A1F-4796-B303-DAAE36394606') {
      //   this.showxg = true
      // }
    },

    rqbh(e) {
      this.cs.CustomerBirthday = e
    },


    qd(type) {
      if (type) {
        if (!this.cs.CustomerName || !this.cs.CustomerTel || !this.rq) {
          return this.$alert('内容不能为空，请检查！', '提示')
        }
        this.cs.CustomerIDCardNo = this.sfz
        this.$store.commit('users/UpCsUser', this.cs)
        this._api.userCurdApi.upCs(this.cs)
        .then(res => {
          this.showxg = false
          if (res.UpdateResult) {
            this.$alert('保存成功！', '提示')
          }else {
            this.$alert('保存失败！', '提示')
          }
        })
      }else {
        this.showxg = false
        this.cs = JSON.parse(JSON.stringify(this.$store.state.users.CsUser))
      }
    },


    khjlbh(type, id) {
      // console.log(this.val1, this.val2)
      this._api.physicianVisits.addKfjl(type, id)
      .then(res => {
        // console.log(res)
        if (!res.InsertResult) {
          this.$alert('添加失败，请稍后再试', '提示')
        }
      })
    },


    getsf() {
      this._api.publicApi.getAddress()
      .then(res => {
        // console.log(res)
        if (res.GetListResult && res.GetListResult.length) {
          this.options = res.GetListResult

          if (this.cs.CustomerAreaIndex) {
            let idx = this.cs.CustomerAreaIndex.split(',')
            this.ssx = [this.options[idx[0]].AreaId, this.options[idx[0]].ListArea[idx[1]].AreaId, this.options[idx[0]].ListArea[idx[1]].ListArea[idx[2]].AreaId]
            // console.log(this.ssx)
          }
        }
      })
    },


    handleChange(e) {
      // console.log(e)
      let idx = [0, 0, 0]
      for (let i = 0; i < this.options.length; i++) {
        if (e[0] == this.options[i].AreaId) {
          idx[0] = i
          for (let j = 0; j < this.options[i].ListArea.length; j++) {
            if (this.options[i].ListArea[j].AreaId == e[1]) {
              idx[1] = j
              for (let k = 0; j < this.options[i].ListArea[j].ListArea.length; k++) {
                if (this.options[i].ListArea[j].ListArea[k].AreaId == e[2]) {
                  idx[2] = k
                  break
                }
              }
              break
            }
          }
          break
        }
      }

      this.cs.CustomerAreaIndex = idx.join(',')

      this.cs.CustomerAreaId = e[2]
    },

    openTjr() {
      this.showtjr = true

      let lag = this.$loading({
        text: '加载中...'
      })
      this._api.userCurdApi.getTjr()
      .then(res => {
        lag.close()
        if (res.Result) {
          this.tjrObj = res.Result
        }else {
          this.showytjr = false
          this.showcztjr = true
        }
      })

    },
    czTjr(type) {
      //4B4400E2 18934337546
      if (type == 1) {
        if (this.tjrObj.CustomerId && this.tjrObj.CustomerTel) {
          let lag = this.$loading({
            text: '加载中...'
          })
          this._api.userCurdApi.getCsTjr(this.tjrObj.CustomerId, this.tjrObj.CustomerTel)
          .then(res => {
            lag.close()
            if (res.GetListResult && res.GetListResult.length) {
              this.showcztjr = false
              this.showbdtjr = true
              this.tjrObj = res.GetListResult[0]
            }else {
              this.$alert("查无此顾客", "提示")
            }
            // console.log(res)
          })
        }else {
          this.$alert("请输入推荐号或者推荐人手机号码", "提示")
        }
      }else {
        let lag = this.$loading({
          text: '绑定中...'
        })
        this._api.userCurdApi.upTjr(this.tjrObj.CustomerId)
        .then(res => {
          lag.close()
          if (res.UpdateResult) {
            this.$alert("绑定成功！", "提示")
            .then(res => {
              this.gbtjr()
            })
          }else {
            this.$alert(res.Msg, "提示")
          }
        })
      }
    },
    gbtjr() {
      this.showtjr = false
      this.showytjr= true
      this.showcztjr=false
      this.showbdtjr= false
      this.tjrObj = {}
    }
  },
  components: {
    ConsultingRecords,
    SalesRecord: () => import('@/components/customerDetailss/salesRecord'),
    consultingBooking: () => import('@/components/customerDetailss/consultingBooking'),
    newZx: () => import('@/components/customerDetailss/newZx'),
    HealthCheckListS: () => import('@/components/customerDetailss/HealthCheckList'),
    JFJL: () => import('@/views/accepts/integral/integralJL')
  }
}
</script>


<style scoped lang="scss">
  .customerDetails {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    position: relative;
    overflow-y: auto;
    @media screen and (max-width: 1400px){
      padding-top: 4vh;
    }
    @media screen and (min-width: 1400px) and  (max-width: 1600px){
      padding-top: 6vh;
    }
    @media screen and (min-width: 1600px){
      padding-top: 10vh;
    }
  }
  .left {
    color: #565555;
    text-align: left;
    .upxx {
      display: flex;
      justify-content: start;
      align-items: center;
      padding-bottom: 0.6rem;
      img {
        display: block;
        width: 0.4rem;
        height: auto;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .title {
      font-size: 20px;
    }
    .gkly {
      display: flex;
      justify-content: start;
      align-items: center;
      padding-bottom: 10px;
      .el-dropdown-link {
        padding: 5px 20px;
        border: 1px solid #000000;
        cursor: pointer;
        margin-left: 15px;
        border-radius: 5px;
      }
    }
  }
  .padding-30 {padding-bottom: 60px; padding-top: 10px;}
  .el-dropdown-menu {
    background: #ece9e9;
    text-align: center;
    li {
      border-bottom: 1px solid #000000;
      padding: 0 40px;
      margin: 0 10px;
    }
  }
  .middle {
    font-size: 20px;
    padding-left: 1.5rem;
    @media screen and (max-width: 1000px){
      font-size: 18px;
    }
    div {
      display: flex;
      cursor: pointer;
      p {
        padding: 3px 0;

      }
      p:nth-child(1) {
        margin-right: 5px;
        width: 5em;
      }
      padding-bottom: 4vh;
    }
    div:nth-child(6) {
      display: block;
      font-size: 14px;
      img {
        display: block;
        margin: 0 auto;
        width: 50px;
        height: auto;
        margin-bottom: 10px;
      }
    }
    div:hover {
      color: #bcbaba;
    }
    .borderNone {
      p:nth-child(1){border-bottom: 2px solid #605d5d;}
    }
  }
  .right {
    padding-left: 0.4rem;
    width: 9rem;
    height: 60vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .bottom {
    width: 100vw;
    position: fixed;
    bottom: 10px;
    .zx {
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  .upcs {
    display: flex;
    align-items: center;
    width: 25vw;
    margin: 20px auto;
    .upcsrs {
      width: 20vw;
    }
    p {padding-right: 10px; width: 6em}
  }
</style>
