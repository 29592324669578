<template>
  <div class="ConsultingRecords">
    <div v-for="(item, idx) in list" :key="idx" class="content">
      <div class="content-box" @click="gos(item.ChooseId, item.ChooseSignPath, item.ChooseOptometristSignPath, item)" :class="sj && idx==0?'xz':''">
        <div class="left">
          <p>{{item.SimpleShop.ShopName?item.SimpleShop.ShopName:'无'}}</p>
          <p>({{item.bh}})</p>
          <div>
            <span>{{item.ChooseAddTime}}</span>
            <span>{{ (item.SimpleUser && item.SimpleUser.UserName) ?item.SimpleUser.UserName:'无'}}</span>
          </div>
        </div>
        <div class="addfz" @click.stop="xzChooseid=item.ChooseId;showyy=true">复查预约</div>
        <div class="addfz" style="color: #000000;" v-if="type" @click.stop="addfz(item.ChooseId)">添加复诊</div>
        <img v-if="type && !item.chooseReportConfirm" class="upimg" src="../../assets/img/xg.png" alt="">
        <img v-if="type && item.chooseReportConfirm" class="upimg" src="../../assets/img/dagou.png" alt="">
      </div>
      <div class="hzbq">
        <p v-for="items in item.ListChooseStep">{{items}}</p>
      </div>
      <div v-for="items in item.ListSonChoose" @click.stop="gos(items.ChooseId,'','', items)" class="fc">
          <div v-if="!type">
            <el-button size="small" @click="gobg(0,items.ChooseId)" type="primary">报告</el-button>
            <el-button size="small" @click="gobg(7,items.ChooseId)" type="primary">病历</el-button>
          </div>
         <span class="bt">{{items.SimpleShop.ShopName?items.SimpleShop.ShopName:'无'}}({{items.ChooseId.slice(0,8).toUpperCase()}})</span>
         <div class="fc-nr">
           <p>{{items.ChooseAddTime}}</p>
           <p>{{items.SimpleUser.UserName?item.SimpleUser.UserName:'无'}}</p>
           <p>复</p>
           <img v-if="type && !items.chooseReportConfirm" class="upimg" src="../../assets/img/xg.png" alt="">
           <img v-if="type && items.chooseReportConfirm" class="upimg" src="../../assets/img/dagou.png" alt="">
         </div>
        <div class="hzbq" style="background: #FFFFFF;justify-content: right;padding-top: 10px;border-bottom: 1px solid #c7c7c7;">
          <p v-for="itemsa in items.ListChooseStep">{{itemsa}}</p>
        </div>
      </div>
      <div class="fns" v-if="!type">
        <el-button size="small" @click="gobg(0,item.ChooseId)" type="primary">报告</el-button>
        <el-button size="small" @click="gobg(7,item.ChooseId)" type="primary">病历</el-button>
      </div>
      <el-button v-if="!type" size="small" style="position: absolute;top: 50px;right: 5px;" @click="showsj" type="primary">数据趋势</el-button>

      <div class="mg" v-if="showjksj">
        <el-button style="position: absolute;right: 60px;top: 60px;" type="danger" @click="showjksj=false">关闭</el-button>
        <iframe style="width: 500px;height: 700px;" :src="src" frameborder="0"></iframe>
      </div>
    </div>
    <el-dialog
        title="排班列表"
        :visible.sync="showyy"
        width="80%"
    >
      <newZx :ChooseId="xzChooseid"></newZx>
    </el-dialog>

  </div>
</template>

<script>
import {Loading} from "element-ui";
import pf from '@/publicFn/baseFn'

export default {
  name: "consultingRecords",
  data() {
    return {
      arr: [1,2,3,4,5,6,7,8],
      list: [],
      sj: '',
      showjksj: false,
      src: '',
      xzChooseid: '',
      showyy: false
    }
  },
  props: {
    type: {
      default: 1
    },
    sx: {
      default: false
    }
  },
  watch: {
    '$store.state.users.CsUser': function (newData){
      if (newData) {
        this.getData()
      }
    },
    '$store.state.physicianVisits.cancellation': function (newData) {
      if (newData) {
        this.list = []
      }
    },
    sx: function (n, o) {
      if (n) {
        this.getData()
      }
    }
  },
  created() {
    if (this.$store.state.users.CsUser) {
      this.getData()
    }
  },
  methods: {
    gos(id, url, sgurl, item) {

      if (!this.type) {
        return;
      }

      if (!this.$store.state.users.CsUser) {
        return
      }

      if (url) {
        this.$store.commit('physicianVisits/upIsqm', false)
      }
      if (sgurl) {
        this.$store.commit('physicianVisits/upissgqm', false)
      }

      pf.clCsData()


      this.$store.commit('physicianVisits/upXzConsulting', id)
      this.$store.commit('physicianVisits/upJump', 1)
      this.$store.commit('users/upCsObj', JSON.parse(JSON.stringify(item)))
      this.$router.push({path: '/'})

    },

    gobg(type,id) {
      if (!this.type) {
        this.$emit('sendC', false)
        this.$store.commit('isShow/upIframeType', {
          type: 0,
          value: id
        })
        this.$router.push({path: `/Iframe?type=${type}&csId=${id}`})
      }
    },

    getData() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      this._api.userCurdApi.getCsConsulting()
          .then(res => {
            // console.log(res)
            loadingInstance.close();
            if (res.GetListWithBusinessResult && res.GetListWithBusinessResult.length > 0) {
              let time = res.GetListWithBusinessResult[0].ChooseAddTime.split(' ')[0]
              time = time.replace(/\//g, '-')
              time==pf.getTimeNo(0)?this.sj = 1:this.sj = 0
              this.list = res.GetListWithBusinessResult.map(item => {
                if (!item.SimpleShop) {
                  item.SimpleShop = {
                    ShopName: '无'
                  }
                }
                if (!item.SimpleUser) {
                  item.SimpleUser = {
                    UserFullName: '无'
                  }
                }
                item.bh = item.ChooseId.slice(0,8).toUpperCase()
                return item
              })
            }else {
              // this.$message({
              //   showClose: true,
              //   message: '暂无数据',
              //   type: 'error'
              // })
              this.list = []
            }
          })
          .catch(err => {
            loadingInstance.close();
          })
    },

    addfz(id) {
      if (!this.$store.state.users.employees) {
        this.$alert("专家未登录，请先登录", "提示")
            .then(res => {
              this.$router.push({
                path: '/employeesLanding',
                query: {types: 1001}
              })
            })
        return
      }
      this._api.physicianVisits.addNewChoose(id)
          .then(res => {
            if (res.msg != 'Success') {
              this.$alert(res.msg, '提示')
              return
            }
            if (res.InsertResult) {
              // this.sx = true
              this.$store.commit('physicianVisits/upXzConsulting',  res.InsertResult.Choose.ChooseId)
              this.$store.commit('users/upCsObj', JSON.parse(JSON.stringify(res.InsertResult.Choose)))
              this.$store.commit('physicianVisits/upJump', 1)
              pf.initTime()
              this.$router.push({path: '/'})
            }
          })
    },
    showsj() {
      this.showjksj = true
      this.src = `https://healthdata.visumall.com/?customerid=${this.$store.state.users.CsUser.CustomerId}&companyid=${this.$store.state.users.user.Company.CompanyId}`
    }
  },
  components: {
    newZx: () => import('@/components/customerDetailss/newZx'),
  }
}
</script>

<style scoped lang="scss">
  .ConsultingRecords {
    padding: 0 5px;
  }
  .content {
    cursor: pointer;
    padding: 5px 0;
    margin-bottom: 1px;
    color: #434242;
    position: relative;
    .fns {
      position: absolute;
      right: 5px;
      top: 10px;
    }
    .content-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ecebeb;
      padding: 4px 0;
    }
    .left {
      width: 4rem;
      padding-left: 0.5rem;
      p {
        font-size: 19px;
        padding-bottom: 10px;
        width: 90%;
        text-align: left;
      }
      div {
        text-align: left;
        span:nth-child(1){
          padding-right: 50px;
        }
      }
    }
  }
  .addfz {
    padding: 5px 10px;
    border: 1px solid #000000;
    margin-left: 0rem;
    margin-right: 0.8em;
  }
  .upimg {
    display: block;
    width: 25px;
    height: auto;
    margin-right: 0.5rem;
  }
  .fc {
    padding-top: 5px;
    background: #FFFFFF;
    //color: #40a7f6;
    text-align: right;
    position: relative;
    .fc-nr {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      p {font-size: 12px;}
      p:nth-child(1) {padding-right: 5vw;}
      p:nth-child(2) {padding-right: 2vw;}
      p:nth-child(3) {
        width: 17px;
        height: 15px;
        padding: 2px;
        color: #5a5959;
        padding-bottom: 4px;
        margin-right: 5px;
        border-radius: 50%;
        border: 1px solid #000000;
        text-align: center;
      }
    }
    .bt {
       padding-right: 9.5vw;
    }
  }
  .xz {
    color: #40a7f6;
  }

  .hzbq {
    display: flex;
    flex-wrap: wrap;
    padding-left: .5rem;
    background: #ecebeb;
    padding-bottom: 5px;
    p {
      padding: 3px;
      border: 1px solid rgb(18,150,219);
      background: #FFFFFF;
      color: rgb(18,150,219);
      border-radius: 5px;
      margin-right: 10px;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
  .mg {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background: rgba(0,0,0,0.5);
  }
</style>
